// Importing react modules
import React, { Component } from 'react'

// Creating button module for default state
class BonusButton extends Component{

    // Button click function
    handleClick = () => {
        this.props.changeCameraPosition(0.6, -900, -25, true, 10, false)
    }

    render = () => {
        return (
            <button className="button-model-state" onClick = { this.handleClick }></button>
        )
    }
}

export default BonusButton