// Importing Router modules
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Importing react modules
import React, { Suspense } from 'react'

// Importing MainComponent module
import MainComponent from './MainComponent'

const App = () => (
  // Implemented routing to get the URL perameters
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={MainComponent}/>
        <Route path="/:id" component={MainComponent}/>
      </Switch>
    </Suspense>
  </Router>
)

export default App