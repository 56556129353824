// Importing react modules
import React, { Component } from 'react'

// Creating button module for straighten state
class StraightenButton extends Component{

    // Button click function
    handleClick = () => {
        this.props.changeCameraPosition(0, 0, -400, false, 37, false)
    }

    render = () => {
        return (
            <button className="button-model-state bottom" onClick = { this.handleClick }></button>
        )
    }
    
}

export default StraightenButton